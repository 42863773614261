<template>
  <div
    :style="{
      'background-image':
        'url(' + require('../../assets/img/vgang-bg.webp') + ')',
    }"
    class="hero"
  >
    <div class="bg-opacity"></div>
    <div class="container">
      <div class="row text-center justify-content-center">
        <div class="message_container col-sm-12 col-md-8">
          <h1 class="hero-title">
              
             Let <span class="green-text"> AI Predict </span> Demand and Match Products to Your 
            <span class="green-text"> Target Market </span>
          </h1>
          <p class="sub-title">
            Elevate your shop success by uncovering winning products that resonate with your target market and unlock untapped profit potential.
          </p>
        </div>
        <div
          class="shopfiy-hero-container d-flex align-items-center justify-content-center"
        >
          <a
            target="_blank"
            class="d-flex align-items-center gap-2 custom-button filled"
            href="https://apps.shopify.com/vgang-dropshipping-retailers?surface_detail=vgang+for+dropshipper&surface_inter_position=1&surface_intra_position=1&surface_type=search"
          >
            <img
              src="@/assets/icons/Shopify_logo_w.svg"
              alt="Shopify"
              title="Shopify"
              loading="lazy"
            />
            Get Shopify App for Free
          </a>
          <span class="star-title mr-4"> Shopify App Store Rating </span>

          <img
            class="start-image"
            src="@/assets/icons/stars.svg"
            alt="vgang"
            title="vgang"
            loading="lazy"
          />
        </div>
        <p class="hero-description mt-3">
          Start for Free, no credit card required.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import buttonLink from "@/components/bases/ButtonLink.vue";
const baseURL = "https://api.vgang.io/api/v1/";
export default {
  name: "Hero",
  components: {
    buttonLink,
  },
  metaInfo: {
    title:
      " VGANG: Premier Eco-friendly Dropshipping Platform - Connect with Top US, EU, and UK Suppliers",
    meta: [
      {
        name: "description",
        content:
          "Explore VGANG, your go-to platform for premium eco-friendly dropshipping. Connect with top US, EU, and UK suppliers, leveraging our AI-powered tools for product discovery and dynamic marketing, and enjoy a sustainable e-commerce experience",
      },
    ],
  },
  props: {
    msg: String,
  },
  // components: { CustomInput },
  data() {
    return {
      email: "",
      isDisabled: true,
      hasError: false,
      errorMessage: "",
      posts: [],
      errors: [],
      category: 33,
    };
  },
  mounted() {
    this.getPosts(this.category);
  },
  methods: {
    checkButtonActiveStatus() {
      if (this.email.length > 0) {
        this.isDisabled = false;
      }
    },
    requestEarlyAccess() {
      if (this.email.length > 0) {
        const params = {
          email: this.email,
        };
        this.axios
          .post(baseURL + "landing/newsletter/add/seller", params)
          .then((response) => {
             
            this.hasError = false;
            this.$bvModal.show("success-modal");
          })
          .catch((e) => {
            // console.log(e);
            if (e.response.status == 409) {
              this.$bvModal.show("alreadyRegistered-modal");
            } else if (e.response.status == 400) {
              this.hasError = true;
              this.errorMessage = "Fix the errors before submiting";
            }
          });
      }
    },
    getPosts(category) {
      axios
        .get(
          "https://blog.vgang.io/wp-json/wp/v2/posts?categories=" +
            category +
            "&per_page=3&offset=0"
        )

        .then((response) => {
           
          this.posts = response.data;
        })
        .catch((err) => {
          console.log(err);
          this.errors.push(err);
        });
    },
  },
};
</script>

<style lang="scss"></style>
