<template>
  <div class="blog">
    <div class="container blog_container">
      <div class="row">
        <h2 class="section-title">VGANG Academy</h2>
        <p class="section-paragraph">
          Your Ultimate Resource for Dropshipping Insights and Supplier Stories
        </p>
        <a
          class="link"
          href="https://blog.vgang.io/category/blog/"
          target="_blank"
        >
          See all
        </a>
      </div>
    </div>
    <div class="container blog_container">
      <div v-for="(post, index) in posts" :key="post.id">
        <div class="each">
          <div
            :style="{
              'background-image': `url(${post.jetpack_featured_media_url})`,
            }"
            class="image"
          ></div>

          <div class="_title" v-html="post.title.rendered"></div>
          <div
            class="paragraph"
            :inner-html.prop="post.excerpt.rendered | truncate(173, '...')"
          ></div>
          <div>
            <div class="link1">
              <a
                :href="post.link"
                target="_blank"
                :aria-label="`you can read more about our post it can be usefull for you ${index}`"
                >Read the article</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Blog",

  data() {
    return {
      posts: [],

      errors: [],
      category: 32,
    };
  },

  mounted() {
    this.getPosts(this.category);
  },

  methods: {
    getPosts(category) {
      axios
        .get(
          "https://blog.vgang.io/wp-json/wp/v2/posts?categories=" +
            category +
            "&per_page=6&offset=0"
        )
        .then((response) => {
          this.posts = response.data;

          this.posts.forEach((post) => {
            let url = post.jetpack_featured_media_url;

            if (url.includes("~vgangio")) {
              url = url.slice(36);
              url = "http://blog.vgang.io" + url;

              post.jetpack_featured_media_url = url;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  filters: {
    truncate: function (text, length, clamp) {
      return text.length > length ? text.slice(0, length) + clamp : text;
    },
  },
};
</script>

<style lang="scss" scoped>
.slide {
  margin-right: 24px;
  margin-bottom: 24px;
}
.blog {
  padding-top: 0 !important;
  margin-bottom: 76px !important;

  @media (max-width: 769px) {
    margin-bottom: 24px;
    padding-top: 24px;
  }
  .section-title {
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 10px;
    color: #344054;
    text-align: center;
  }
  .sub-title {
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111418;
    display: block;
    margin: 8px auto;
    //margin-bottom: 72px;
    @media (max-width: 768px) {
      font-size: 28px;
    }
  }
  .section-paragraph {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #1d2936;
    text-align: center;
    //margin-bottom: 70px;
  }

  .blog_container {
    display: flex;

    flex-wrap: wrap;

    justify-content: space-evenly;
    @media (max-width: 992px) {
      justify-content: center;
    }

    .each {
      background: unset;
      padding: 20px;
      border-radius: 10px;

      position: relative;
      box-shadow: unset !important;
      overflow: hidden;
      min-width: 200px;
      width: 400px;
      @media (min-width: 992px) and (max-width: 1400px) {
        max-width: 310px;
        height: 500px;
      }
      @media (min-width: 1200px) and (max-width: 1400px) {
        max-width: 370px;
        height: 500px;
      }
      @media (max-width: 991px) {
        margin-bottom: 12px;
      }

      .image-container {
        width: 100%;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 20px;
      }

      .image {
        max-width: 100%;
        height: 205px;
        vertical-align: middle;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
      }

      ._title {
        font-weight: bold;
        font-stretch: normal;

        letter-spacing: normal;
        color: #344054;
        margin-bottom: 20px;
        margin-top: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .paragraph {
        font: 14px "Open Sans", Tahoma;

        left: 20px;
        padding-right: 20px;
        margin-top: 14px;

        p {
          font-size: 14px;
          font-weight: 600;
          color: #475467;
        }
      }
    }

    .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        padding: 20px;
        flex-wrap: wrap;
      }
      @media (min-width: 820px) and(max-width:1280px) {
        justify-content: center;
      }

      .each1 {
        max-width: 600px;
        width: 100%;
        padding: 4%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;
        @media (min-width: 820px) and(max-width:1280px) {
          justify-content: space-between;
          max-width: 470px;
        }
        ._title {
          font-size: 30px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #111418;
          @media (max-width: 768px) {
            font-size: 26px;
          }
        }
        .paragraph {
          font-size: 18px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          text-align: justify;
          color: #000f29;
          display: block;
          p {
            font-size: 14px;
            font-weight: 600;
            color: #475467;
          }
          @media (max-width: 768px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.blog-content {
  padding-bottom: 100px;
  ._title {
    font-weight: bold;
    margin-bottom: 600px;
  }
  .first-p {
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
  }
  h2 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    line-height: 28px;
  }

  .image-container {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 60px;
    ._image {
      width: 100%;
    }
  }
}
.blog {
  padding-top: 96px;
  margin-bottom: 72px;
  @media (max-width: 768px) {
    margin-bottom: 24px;
    padding-top: 24px;
  }
  .link {
    color: #008f4a !important;
    text-align: center;
    //color: #006635;
    font-size: 15px;
    font-weight: bold;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    top: 90px;
  }
  .link2 {
    color: #008f4a;
    text-decoration: underline;
    //color: #006635;
    font-size: 15px;
    font-weight: bold;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    top: 90px;
  }

  .link1 {
    text-decoration: underline;
    font-weight: 600;
    display: block;
    color: #008f4a;
    font-size: 15px;
    margin-top: 110px;
    position: absolute;
    bottom: 0;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

    @media (min-width: 992px) and (max-width: 1400px) {
      //justify-content:center;
      display: block;
      margin-top: 150px;
    }
  }
  .each1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    ._title {
      font-size: 32px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #111418;
      @media (max-width: 768px) {
        font-size: 26px;
      }
    }
    .paragraph {
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: justify;
      color: #000f29;
      display: block;
      p {
        font-size: 14px;
        font-weight: 600;
        color: #475467;
      }
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
}
</style>
