<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="custom-label"
      :class="hasError ? 'hasError' : ''"
      >{{ label }}</label
    >
    <div class="input-wrapper">
      <img
        :src="getInputIcon(icon)"
        alt="icon"
        class="input-icon"
        :class="mainPageInput ? 'mainpage' : ''"
      />
      <input
        v-if="mainPageInput"
        :type="type"
        :name="id"
        :id="id"
        :value="value"
        required="required"
        :placeholder="placeholder ? placeholder : ''"
        class="custom-input mainpage-input"
        :class="hasError ? 'hasError' : ''"
        @input="updateValue($event.target.value), checkForContent($event)"
        @keyup="$emit('check-input')"
      />
      <input
        v-else
        :type="type"
        :name="id"
        :id="id"
        :value="value"
        required="required"
        :placeholder="placeholder ? placeholder : ''"
        class="custom-input"
        :class="hasError ? 'hasError' : ''"
        @input="updateValue($event.target.value), checkForContent($event)"
        @keyup="$emit('check-input')"
      />
      <span class="input-hint" :class="hasError ? 'hasError' : ''">{{
        errorMessage
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalInput",
  props: [
    "id",
    "label",
    "icon",
    "websiteURL",
    "type",
    "value",
    "placeholder",
    "mainPageInput",
  ],
  data() {
    return {
      companyName: "",
      errorMessage: "",
      hasError: false,
    };
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    getInputIcon(icon) {
      var InputIcon = require.context("@/assets/img/form/", false, /\.png$/);
      return InputIcon("./" + icon + ".png");
    },
    checkForContent(e) {
      let val = e.target.value;
      if (this.id === "cellNumber" && !/^[+]*[0-9]{0,15}$/.test(val)) {
        this.hasError = true;
        this.errorMessage = "Cellphone is not correct";
      } else if (
        this.id === "websiteURL" &&
        !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/.test(
          val
        )
      ) {
        this.hasError = true;
        this.errorMessage = "Website is not correct";
      } else if (
        this.id === "email" &&
        !/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          val
        )
      ) {
        this.hasError = true;
        this.errorMessage = "Email is not correct";
      } else if (e.target.value.length == 0) {
        this.hasError = true;
        this.errorMessage = "Field is required";
      } else {
        this.hasError = false;
        this.errorMessage = "";
      }
    },
  },
};
</script>

<style></style>
