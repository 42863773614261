<template>
  <section class="be-part-of-solution-section">
    <div class="container">
      <div class="row">
        <div class="col-7">
          <p class="section-paragraph">10x cheaper. 10x faster. Higher revenue growth rate.</p>
          <h3 class="section-title">
            From Chaos to Control:
The Art of VGANG
          </h3>
        </div>
        <div class="description-and-image row">
          <div class="description-container col-xl-5 col-md-6 px-0">
            <p class="description">
              Our algorithms immerse in product and shopper data and seamlessly connect online stores with in-demand, planet-friendly items.
              <br/>
<ul style="margin-top: 20px;">
  <li>
    AI-driven curation of sustainable products
  </li>
  <li>
    Completing missing product information
  </li>
  <li>
    Predicting demand based on shop analytics
  </li>
  <li>
    Seamless matching with in-demand, planet-friendly items.
  </li>
</ul>
            </p>
            <div class="shopfiy-hero-container d-flex pt-5 px-0">
              <a
                target="_blank"
                class="d-flex align-items-center gap-2 custom-button filled mr-5"
                href="https://apps.shopify.com/vgang-dropshipping-retailers?surface_detail=vgang+for+dropshipper&surface_inter_position=1&surface_intra_position=1&surface_type=search"
              >
                <img
                  src="@/assets/icons/Shopify_logo_w.svg"
                  alt="Shopify"
                  title="Shopify"
                  loading="lazy"
                />
                Get Shopify App for Free
              </a>
              <a
                class="book-demo-btn d-flex align-items-center gap-2 custom-button"
                href="https://calendly.com/vgang/vgang-for-dropshipers"
                target="_blank"
              >
                Book a demo
              </a>
            </div>
          </div>
          <div class="col-xl-7 col-md-6 px-0">
            <img
              class="bg-image px-0"
              src="@/assets/img/hero.webp"
              alt="Shopify"
              title="Shopify"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.be-part-of-solution-section {
  margin-bottom: 76px;
  .section-title {
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #344054;
    margin-bottom: 24px;
  }
  .section-paragraph {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #344054;
  }
  .description {
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
  }
  .bg-image {
    width: 100%;
  }
  .custom-button {
    font-size: 12px;
  }

  .book-demo-btn {
    margin-right: 44px;
    @include lg {
      margin-right: 7px;
      font-size: 12px;
    }
  }
}
</style>
