<template>
  <div class="home">
    <Hero />
    <UserComment
      description=" This app is highly recommended! It differs from
    other dropshipping apps that 'only' offer bulk products and don't have a
    clear focus. VGANG attaches great importance to sustainable and eco-friendly
    products and thus offers a selected and convincing range. We are very
    convinced of VGANG and are happy that there is finally a dropshipping
    provider that offers both suppliers and retailers the opportunity to offer
    'green' products. First class and very friendly support! We can only
    recommend this app!"
      author="Gruene Liesl"
      profile="https://gruene-liesl.de/cdn/shop/files/Gruene_Liesl_Logo.png?v=1673956630"
      url="https://www.gruene-liesl.de/"
    />
    <BeApartOfTheSolution class="hidden-on-mobile" />
    <WhyVgang />


    <Blog class="hidden-on-mobile" />
    <QaBaseComponent
      titleFor="Dropshippers"
      api="https://blog.vgang.io/wp-json/wp/v2/posts?categories=33&per_page=6&offset=0"
    />
    <JoinTheMovement />
  </div>
</template>

<script>
const baseURL = "https://api.vgang.io/api/v1/";
import Hero from "@/components/home/Hero.vue";
import JoinTheMovement from "../components/bases/JoinTheMovement.vue";
import BeApartOfTheSolution from "@/components/home/BeApartOfTheSolution.vue";
import Blog from "@/views/blog/list.vue";
import QaBaseComponent from "../components/bases/QaBaseComponent.vue";
import ModalInput from "@/components/ModalInput";
import UserComment from "@/components/home/UserComment.vue";
import WhyVgang from "../components/home/WhyVgang.vue";
export default {
  name: "Home",
  components: {
    Hero,
    WhyVgang,
    UserComment,
    JoinTheMovement,
    BeApartOfTheSolution,
    Blog,
    QaBaseComponent,
    ModalInput,
  },
  data() {
    return {
      email: "",
      hasError: false,
      errorMessage: "",
      isDisabled: true,
    };
  },
  methods: {
    checkButtonActiveStatus() {
      if (this.email.length > 0) {
        this.isDisabled = false;
      }
    },
    requestEarlyAccess() {
      const params = {
        email: this.email,
      };
      this.axios
        .post(baseURL + "landing/newsletter/add/seller", params)
        .then((response) => {
          this.$bvModal.hide("earlyaccess-modal");
          this.$bvModal.show("success-modal");
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status == 409) {
            this.$bvModal.hide("earlyaccess-modal");
            this.$bvModal.show("alreadyRegistered-modal");
          } else if (e.response.status == 400) {
            this.hasError = true;
            this.errorMessage = "Fix the errors before submiting";
          }
        });
    },
  },
};
</script>
