<template>
  <section class="why-vgang-section">
    <div class="container">
      <div class="getting-started">
        <div class="row">
          <div class="col-sm-12">
            <h1 class="section-title">The Path To Retail Success</h1>
            <p class="section-paragraph">
              Data Alchemy, Effortless Personalized Pairing, Legwork Liberation
            </p>
          </div>
        </div>
        <div class="cards-container">
          <div class="row">
            <div class="col-xl-4 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/whyVgang/Content genaration & translation.png"
                alt=" Earth friendly products"
                title="Earth friendly products"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
                 Content genaration & translation
                </h3>
                <p class="description mt-3">
                  Missing information, SEO optimization, Tagging, Translation
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/whyVgang/Demand prediction & forcasting.png"
                alt=" Sourced from local suppliers"
                title="Sourced from local suppliers"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
                   Demand prediction & forcasting
                </h3>
                <p class="description mt-3">
                  Preferences, long-term sales behaviour,  market behaviour , fluctuating trends
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/whyVgang/AICurated eco products.png"
                alt=" Super fast shipping"
                title=" Super fast shippings"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
              AI-Curated eco products
                </h3>
                <p class="description mt-3">
                  Material analysis, review analysis, Eco scroe, Supplier service analysis
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "GettingStarted",
  props: {
    msg: String,
  },
};
</script>
<style lang="scss" scoped>
.card {
  background: unset;
}
</style>
